
import baseConstants from '../../store/base/-constants'

export default {
  name: 'MoleculeFilterTriggerUniverse',
  computed: {
    isOpen() {
      return this.$store.state?.base?.filterOpen || false
    }
  },
  methods: {
    toggleFilter() {
      this.$store.dispatch(baseConstants.withNamespace(baseConstants.action.TOGGLE_FILTER))
    }
  }
}
