
import { CurrentView, FloorViewMode } from '~/store/building/-constants'
import tutorialConstants from '~/store/tutorial/-constants'

export default {
  name: 'MoleculeBuildingZoomSlider',
  data() {
    return {
      viewModes: FloorViewMode,
      zoomSettings: {
        zoomValue: 0,
        minValue: 0,
        maxValue: 100
      }
    }
  },
  computed: {
    buildingStore() {
      return this.$store.state.building
    },
    viewMode() {
      return this.buildingStore.floorViewMode
    },
    engine3d() {
      return this.$engine3d || window.engine3d
    }
  },
  methods: {
    handleDrag(e) {
      if (e.target.id !== 'dragger') {
        return
      }
      const parentRect = e.target.parentElement.getBoundingClientRect()
      const zoomRect = e.target.getBoundingClientRect()
      const absoluteYPositionWithinContainer = e.pageY - window.pageYOffset
      const cursorVal =
        e.pageY !== 0
          ? parentRect.bottom - absoluteYPositionWithinContainer - zoomRect.height / 2
          : 0
      if (cursorVal < this.zoomSettings.maxValue - 10 && cursorVal > this.zoomSettings.minValue) {
        this.zoomSettings.zoomValue = cursorVal
        this.setEngineZoomValue()
      }
    },
    handleDragStart(e) {
      if (e.target.id !== 'dragger') {
        return
      }
      e.dataTransfer.setDragImage(e.target, window.outerWidth + 100, window.outerHeight + 100)
    },
    increaseZoomValue() {
      if (this.zoomSettings.zoomValue < this.zoomSettings.maxValue - 10) {
        this.zoomSettings.zoomValue = Math.min(
          this.zoomSettings.maxValue - 10,
          this.zoomSettings.zoomValue + 5
        )
        this.setEngineZoomValue()
      }
    },
    decreaseZoomValue() {
      if (this.zoomSettings.zoomValue > this.zoomSettings.minValue) {
        this.zoomSettings.zoomValue = Math.max(
          this.zoomSettings.minValue,
          this.zoomSettings.zoomValue - 5
        )
        this.setEngineZoomValue()
      }
    },
    setEngineZoomValue() {
      if (!this.$refs.dragger) return
      this.$refs.dragger.style.bottom = `${
        (this.zoomSettings.zoomValue * 100) / this.zoomSettings.maxValue
      }%`
      const manager = this.engine3d.getClientManager()
      manager?.setZoomValue(this.zoomSettings.maxValue - this.zoomSettings.zoomValue)
    }
  }
}
