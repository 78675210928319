
import universeConstants from '~/store/universe/-constants'
export default {
  name: 'MoleculeUniverseOrientationBar',
  props: {
    infoEnabled: {
      required: false,
      type: Boolean,
      default: true
    },
    zoomEnabled: {
      required: false,
      type: Boolean,
      default: true
    },
    orientationEnabled: {
      required: false,
      type: Boolean,
      default: true
    },
    showClose: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      orientations: ['N', 'NE', 'E', 'SE', 'S', 'SV', 'V', 'NV'],
      currentOrientation: 2,
      defaultOrientation: 2,
      project: this.$store.state.project.projects[0]
    }
  },

  computed: {
    i18nActive() {
      return !!this.$store.state.base.meta.generalConfig.i18nActive
    },
    activeProject() {
      return this.$store.state.project.activeProject
    },
    orientation() {
      return this.orientations[this.currentOrientation]
    },
    open() {
      return this.$store.state?.universe?.infoBox?.open || false
    }
  },
  watch: {
    activeProject() {
      const activeProject = this.$store.state.project.activeProject
      this.$store.state.project.projects.forEach((project) => {
        if (project.slug === activeProject) {
          this.project = project
          return project
        }
      })
    }
  },
  methods: {
    handleZoomTrigger(direction) {
      switch (direction) {
        case 'in':
          this.$emit('zoom-in')
          break
        case 'out':
          this.$emit('zoom-out')
          break
      }
    },
    handleOrientationTrigger() {
      this.currentOrientation = this.currentOrientation > 0 ? this.currentOrientation - 1 : 7
      // if (this.currentOrientation > this.orientations.length - 1) this.currentOrientation = 0;
      this.$emit('orientation', this.orientations[this.currentOrientation])
    },
    handleInfoTrigger() {
      this.$store.dispatch(
        universeConstants.withNamespace(universeConstants.action.TOGGLE_INFO_BOX),
        {
          open: !this.open
        }
      )
    },
    handleCloseTrigger() {
      this.$emit('close')
    },
    resetOrientation() {
      this.currentOrientation = this.defaultOrientation
    }
  }
}
