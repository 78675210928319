
import { CurrentView, FloorViewMode, SpaceFloorViewMode } from '~/store/building/-constants'
import tutorialConstants from '~/store/tutorial/-constants'

export default {
  name: 'MoleculeBuildingSpaceSwitchFloor',
  data() {
    return {
      viewModes: FloorViewMode
    }
  },
  computed: {
    building() {
      return this.$store.state.base?.landlordDetails?.buildings?.find(
        (b) => b.id === this.space.building_id
      )
    },
    space() {
      let space = {}
      if (this.$route.params.space) {
        space =
          this.$store.state.base?.landlordDetails?.spaces.find((s) => {
            return s.id === parseInt(this.$route.params.space)
          }) || {}
      } else if (this.$store.state.project?.project?.currentSpaceId) {
        space =
          this.$store.state.base?.landlordDetails?.spaces.find((s) => {
            return s.id === parseInt(this.$store.state.project?.project?.currentSpaceId)
          }) || {}
      }
      return space
    },
    buildingStore() {
      return this.$store.state.building
    },
    spaceViewMode() {
      return this.buildingStore.spaceFloorViewMode
    },
    isFloorView() {
      return this.buildingStore.currentView === CurrentView.FLOOR
    },
    viewMode() {
      return this.buildingStore.floorViewMode
    },
    currentSpace() {
      return this.buildingStore.space.spaceData || {}
    },
    isDualLevel() {
      // is_dual_level property means that you have ground, mezzanine or/and terrace
      // if you want to have only ground and mezzanine, the value should be equal to 1 in the space table (database table)
      // if you want to have all three options available (ground, mezzanine and terrace), the value should be equal to 2
      // if you don't want to have any of these options, the value should be null.
      return this.currentSpace.is_dual_level
    },
    cameraCoordinates() {
      const cameraCoordinates = this.space?.camera_coordinates || []
      return [...cameraCoordinates]?.sort((a, b) => a.order - b.order) || []
    },
    engine3d() {
      return this.$engine3d || window.engine3d
    }
  },
  methods: {
    changeLevel(level) {
      this.$store.dispatch('building/changeSpaceViewMode', level)
      const manager = this.engine3d.getClientManager()
      manager.showLevelElements(level)
      if (this.spaceViewMode !== SpaceFloorViewMode.ALL) {
        manager.updateLevelControls(level)
        if (this.viewMode === FloorViewMode.FIRST_PERSON) {
          manager.showLevelElements(SpaceFloorViewMode.ALL)
        }
      }
      if (window.innerWidth < 768 && !this.$store.state.tutorial.MEZZANINE_GROUND_CHANGER_MOBILE) {
        this.$store.dispatch(
          tutorialConstants.namespaceAction.TRIGGER_PANEL,
          'MEZZANINE_GROUND_CHANGER_MOBILE'
        )
      }
      if (window.innerWidth >= 768 && !this.$store.state.tutorial.MEZZANINE_GROUND_CHANGER) {
        this.$store.dispatch(
          tutorialConstants.namespaceAction.TRIGGER_PANEL,
          'MEZZANINE_GROUND_CHANGER'
        )
      }
    },
    displayLevelButton(searchCriteria = []) {
      return (
        this.cameraCoordinates.length > 0 &&
        this.cameraCoordinates.find((c) => searchCriteria.includes(c.level))
      )
    }
  }
}
